import React, { Component } from "react";
import { translate } from "react-i18next";
import { actions as uiActions, ModalTypes } from 'shared/redux/ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18next from "i18next";
import { selectors as authSelectors } from 'shared/redux/auth';
import MetaTags from 'react-meta-tags';
import Icon1 from 'home/pricing/included-in/pic-includedin-3.svg';
import Icon2 from 'home/pricing/included-in/pic-includedin-2.svg';
import Icon3 from 'home/pricing/included-in/pic-includedin-1.svg';
import Icon4 from 'home/pricing/included-in/pic-includedin-4.svg';
import Icon5 from 'home/pricing/included-in/pic-includedin-5.svg';
import Icon6 from 'home/pricing/included-in/pic-includedin-6.svg';
import IconBenefit1 from 'home/pricing/benefits/ic_presentation.svg';
import IconBenefit2 from 'home/pricing/benefits/ic_360.svg';
import IconBenefit3 from 'home/pricing/benefits/ic_browser.svg';
import IconBenefit4 from 'home/pricing/benefits/ic_share.svg';
import IconBenefit5 from 'home/pricing/benefits/ic_embed.svg';
import IconBenefit6 from 'home/pricing/benefits/ic_password.svg';
import IconBenefit7 from 'home/pricing/benefits/ic_mobile.svg';
import IconBenefit8 from 'home/pricing/benefits/ic_knowledgebase.svg';
import Grid from '@material-ui/core/Grid';
import './pricing.scss';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import AssetHelper from 'AssetHelper';
import classNames from 'classnames';
import Tabs from '../../components/tabs/tabs';
import OfferCard from './components/OfferCard';
import CurrencyDropdown from './components/CurrencyDropdown';
import { Button, Typography } from "@material-ui/core";
import { actions as offersActions } from 'shared/redux/auth';
import {actions as presentationsActions} from "../../shared/redux/presentations";
import Spinner from 'components/spinner';

const formatPrice = v => v / 100;
const Periods = {
  monthly: 'monthly',
  yearly: 'yearly',
};
class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: Periods.monthly,
      selectedCurrency: 'PLN',
      equalHeight: "auto",
      screenWide: window.innerWidth > 1279,
      selectedPlan: null
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.onResizeUpdate = this.onResizeUpdate.bind(this);
    this.openContactModal = this.openContactModal.bind(this);
    this.changeSelectedCurrency = this.changeSelectedCurrency.bind(this);
  }
  changeSelectedCurrency(selectedCurrency) {
    this.setState({ selectedCurrency, selectedPlan: null });
    this.props.fetchOffers({ currency: selectedCurrency });
  }
  openContactModal() {
    this.props.showModal({
      modalType: ModalTypes.Contact,
    });
  }
  onTabChange(value) {
    this.setState({
      period: value,
    });
  }

  onResizeUpdate() {
    this.setState({ screenWide: window.innerWidth > 1279 });
  }
  componentDidMount() {
    window.addEventListener('resize', this.onResizeUpdate.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeUpdate.bind(this));
  }
  render() {
    let { selectedCurrency } = this.state;
    const { t, user, offers } = this.props,
      { screenWide } = this.state,
      isLoggedIn = user.state === 'loggedIn',
      offersData = (offers && offers.data) || [],
      pricingTabs = [
        {
          heading: t('pricing.column1.heading'),
          price: null,
          button: t('pricing.column1.button'),
          description: t('pricing.column1.description'),
          destiny: t('pricing.column1.destiny'),
          features: [
            t('pricing.column1.feature1'),
            t('pricing.column1.feature2'),
            t('pricing.column1.feature3'),
            t('pricing.column1.feature4'),
            t('pricing.column1.feature5'),
            t('pricing.column1.feature6'),
            // t('pricing.column1.feature7'),
            t('pricing.column1.feature8')
          ]
        },
        {
          heading: t('pricing.column2.heading'),
          price: null,
          button: t('pricing.column2.button'),
          description: t('pricing.column2.description'),
          destiny: t('pricing.column2.destiny'),
          features: [
            t('pricing.column2.feature1'),
            t('pricing.column2.feature2'),
            t('pricing.column2.feature3'),
            t('pricing.column2.feature4'),
            t('pricing.column2.feature5'),
            t('pricing.column2.feature6'),
            // t('pricing.column2.feature7'),
            t('pricing.column2.feature8'),
            t('pricing.column2.feature9')
          ]
        },
        {
          heading: t('pricing.column3.heading'),
          price: null,
          button: t('pricing.column3.button'),
          description: t('pricing.column3.description'),
          destiny: t('pricing.column3.destiny'),
          features: [
            t('pricing.column3.feature1'),
            t('pricing.column3.feature2'),
            t('pricing.column3.feature3'),
            t('pricing.column3.feature4'),
            t('pricing.column3.feature5'),
            t('pricing.column3.feature6'),
            t('pricing.column3.feature8'),
            t('pricing.column3.feature9'),
            t('pricing.column3.feature7'),
            t('pricing.column3.feature10'),
            t('pricing.column3.feature11'),
            t('pricing.column3.feature12'),
            t('pricing.column3.feature13'),
            t('pricing.column3.feature14'),
            t('pricing.column3.feature15'),
            t('pricing.column3.feature16'),
          ]
        }
      ],
      pricingIncluded = [
        { text: t('pricing.includedInBox1'), icon: <Icon1 /> },
        { text: t('pricing.includedInBox2'), icon: <Icon2 /> },
        { text: t('pricing.includedInBox3'), icon: <Icon3 /> },
        { text: t('pricing.includedInBox4'), icon: <Icon4 /> },
        { text: t('pricing.includedInBox5'), icon: <Icon5 /> },
        { text: t('pricing.includedInBox6'), icon: <Icon6 /> }
      ],
      functionalities = [
        { text: t('pricing.functionalitiesBox1'), img: AssetHelper.staticPath('/static/home/pricing/functionalities/img-functionalities-1.png') },
        { text: t('pricing.functionalitiesBox2'), img: AssetHelper.staticPath('/static/home/pricing/functionalities/img-functionalities-2.png') },
        { text: t('pricing.functionalitiesBox3'), img: AssetHelper.staticPath('/static/home/pricing/functionalities/img-functionalities-3.png') }
      ];

    let { selectedPlan } = this.state;

    for(let i = 0; i < 3; i++) {
      if (!offersData[i]) break;

      let offer = offersData[i],
        price,
        { nominalNetPrice, currency, selected, code } = offer;

      if (nominalNetPrice) price = nominalNetPrice / 100;

      pricingTabs[i].price = price;
      pricingTabs[i].currency = currency;
      pricingTabs[i].selected = selected;
      pricingTabs[i].code = code;
    }

    let onOfferLick = (tab) =>
      () => {
        if (isLoggedIn) {
          window.location = '/panel/';
        } else {
          this.props.showModal({modalType: ModalTypes.Registration})
        }
      };

    const benefits = [
      { Icon: IconBenefit1, text: t("pricing.benefits.text1")},
      { Icon: IconBenefit2, text: t("pricing.benefits.text2")},
      { Icon: IconBenefit3, text: t("pricing.benefits.text3")},
      { Icon: IconBenefit4, text: t("pricing.benefits.text4")},
      { Icon: IconBenefit5, text: t("pricing.benefits.text5")},
      { Icon: IconBenefit6, text: t("pricing.benefits.text6")},
      { Icon: IconBenefit7, text: t("pricing.benefits.text7")},
      { Icon: IconBenefit8, text: t("pricing.benefits.text8")},
    ];

    let mini, basic, business, advanced, miniAnnual, basicAnnual, businessAnnual, advancedAnnual, tabs;
    if (offers && offers.data && offers.data.length) {
      mini = offers.data.find(o => o.code.includes('MINI_MONTHLY'));
      basic = offers.data.find(o => o.code.includes('BASIC_MONTHLY'));
      business = offers.data.find(o => o.code.includes('BUSINESS_MONTHLY'));
      advanced = offers.data.find(o => o.code.includes('ADVANCED_MONTHLY'));
      miniAnnual = offers.data.find(o => o.code.includes('MINI_YEARLY'));
      basicAnnual = offers.data.find(o => o.code.includes('BASIC_YEARLY'));
      businessAnnual = offers.data.find(o => o.code.includes('BUSINESS_YEARLY'));
      advancedAnnual = offers.data.find(o => o.code.includes('ADVANCED_YEARLY'));
      tabs = [
        {
          label: t("pricing.benefits.monthly"),
          type: 'MONTHLY',
          offers: [
            {
              ...mini,
            },
            {
              ...basic,
            },
            {
              ...business,
            },
            {
              ...advanced,
            },
          ]
        },
        {
          label: t("pricing.benefits.annual"),
          type: 'ANNUAL',
          offers: [
            {
              ...miniAnnual,
            },
            {
              ...basicAnnual,
            },
            {
              ...businessAnnual,
            },
            {
              ...advancedAnnual,
            },
          ]
        }
      ];
      if (!selectedPlan) {
        selectedPlan = tabs[0];
      }
    }

    const currency = selectedPlan && selectedPlan.offers[0].currency;
    if (currency) {
      selectedCurrency = currency;
    }
    let businessOfferPrice;
    if (currency === 'PLN') {
      businessOfferPrice = { month: 625, year: 6249 };
    } else if (currency === 'USD') {
      businessOfferPrice = { month: 160, year: 1599 };
    } else {
      businessOfferPrice = { month: 140, year: 1399 };
    }

    const langPl = i18next.language.includes('pl');

    console.log('selectedPlan',selectedPlan);

    return (
      <section id="pricing" className="pricing">
        <MetaTags>
          <title>{t("pricing.metaTitle")}</title>
          <meta name="description" content={t("pricing.metaDescription")} />
          <meta name="keywords" content={t("pricing.metaKeywords")} />
        </MetaTags>
        <div className="hero">
          <div className="banner" />
          <h1>{t("pricing.benefits.evryPricing")}</h1>
        </div>
        <section className="benefits-section">
          <h1>{t("pricing.benefits.evryBenefits")}</h1>
          <Grid container className="container">
            {benefits.map(({ Icon, text }) => {
              return (
                <Grid container lg={3} xs={12} className={classNames('benefit-item')}>
                  <Icon className="icon" />
                  <div className="description">{text}</div>
                </Grid>
              )})
            }
            </Grid>
        </section>
          <section className="offers-section">
            <div className="title">{t("pricing.selectPaymentType")}</div>
            {tabs && tabs.length ? (
              <Tabs tabs={tabs} onClick={tab => this.setState({ selectedPlan: tab })}/>
            ) : (<div style={{height: 34}} />)}
            <CurrencyDropdown currency={selectedCurrency} onCurrencyChange={this.changeSelectedCurrency} />
            <Grid container className="offers-cards">
              {selectedPlan && selectedPlan.offers && selectedPlan.offers.length ? (
                <React.Fragment>
                  {selectedPlan.offers.map((offer) => {
                    return (
                      <OfferCard offer={offer} type={selectedPlan.type} />
                    );
                  })}
                </React.Fragment>
              ) : (
                <Spinner style={{width: 100, height: 100, marginTop: 200, marginBottom: 400}} />
              )}
            </Grid>
            <Typography className="currency-info">{t("pricing.currencyInfo")}</Typography>
          </section>
        <section className="business-offer-section">
          <Grid className="container">
            <div className="header">{t("pricing.businessOffer.text1")}</div>
            <div className="text1">{t("pricing.businessOffer.text2")}</div>
            <div>{t("pricing.businessOffer.text3")}</div>
            <div>{t("pricing.businessOffer.text4")}</div>
          </Grid>
          <Grid className="offer-banner">
            <div>{t("pricing.businessOffer.text5")}</div>
            <Grid>
              <div className="price">
                {selectedPlan && selectedPlan.type === 'MONTHLY' ? (
                  <span>{businessOfferPrice.month}</span>
                ) : (
                  <span>{businessOfferPrice.year}</span>
                )}
              </div>
              <div>
                <span style={{marginRight: 5}}>{selectedPlan && selectedPlan.offers && selectedPlan.offers[0] && selectedPlan.offers[0].currency}</span>
                {selectedPlan && selectedPlan.type === 'MONTHLY' ? (
                  <span>{t("pricing.businessOffer.text7")}</span>
                ) : (
                  <span>{t("pricing.businessOffer.text7_1")}</span>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid className="business-nolimit">
            <div><b>{t("pricing.businessOffer.text8")}</b>{t("pricing.businessOffer.text9")}</div>
            <div>{t("pricing.businessOffer.text10")}<b>{t("pricing.businessOffer.text11")}</b></div>
          </Grid>
          <Grid className="business-cards">
            <Grid className="business-card card-1">
              <div>{t("pricing.businessOffer.text12")}</div>
              <div><b>{t("pricing.businessOffer.text13")}</b>{t("pricing.businessOffer.text14")}</div>
              <div><b>{t("pricing.businessOffer.text15")}</b>{t("pricing.businessOffer.text16")}</div>
              <div><b>{t("pricing.businessOffer.text17")}</b> {t("pricing.businessOffer.text17_1")}</div>
              <div><b>{t("pricing.businessOffer.text18")}</b> {t("pricing.businessOffer.text18_1")}</div>
              <div><b>{t("pricing.businessOffer.text19")}</b> {t("pricing.businessOffer.text19_1")}</div>
              {langPl && (<div>{t("pricing.businessOffer.text30")} <b>{t("pricing.businessOffer.text30_1")}</b></div>)}
              {!langPl && (<div><b>{t("pricing.businessOffer.text30")}</b> {t("pricing.businessOffer.text30_1")}</div>)}
            </Grid>
            <Grid className="business-card card-2">
              <div><b>{t("pricing.businessOffer.text20")}</b></div>
              <div><b>{t("pricing.businessOffer.text21")}</b> {t("pricing.businessOffer.text21_1")}</div>
              <div><b>{t("pricing.businessOffer.text22")}</b> {t("pricing.businessOffer.text22_1")}</div>
              <div><b>{t("pricing.businessOffer.text23")}</b> {t("pricing.businessOffer.text23_1")}</div>
              <div><b>{t("pricing.businessOffer.text24")}</b></div>
              <div><b>{t("pricing.businessOffer.text25")}</b></div>
              <div><b>{t("pricing.businessOffer.text26")}</b> {t("pricing.businessOffer.text26_1")}</div>
            </Grid>
          </Grid>
          <Grid>
            <Button className="business-btn" onClick={this.openContactModal}>{t("pricing.businessOffer.text27")}</Button>
            <div className="business-contact">{t("pricing.businessOffer.text28")}</div>
          </Grid>
        </section>
        <section className="functionalities__wrapper">
          <h4 className="functionalities__heading pricing__heading">{t('pricing.functionalitiesHeading')}</h4>
          <div className="functionalities__content">
            {
              functionalities.map((item, i) => {
                return (
                  <div key={i} className="functionalities__box">
                    <img className="functionalities__box-img" src={item.img} alt="" />
                    <p className="functionalities__box-desc">{item.text}</p>
                  </div>
                );
              })
            }
          </div>
        </section>
        <section className="more-questions wrapper">
          <h4 className="more-questions__heading">{t('pricing.moreQuestionsHeading')}</h4>
          <div className="more-questions__buttons">
            {/* button below - kayako link deleted - replacement to be consulted */}
            <a className="button button--border" onClick={() => this.props.showModal({modalType: ModalTypes.Support})}>
              {t('pricing.moreQuestionsButton1top')}
              <br />
              <span className="yellow">{t('pricing.moreQuestionsButton1bottom')}</span>
            </a>
            <NavLink
              className="button button--border"
              to={'/faq'}>
              {t('pricing.moreQuestionsButton2top')}
              <br />
              <span className="yellow">{t('pricing.moreQuestionsButton2bottom')}</span>
            </NavLink>
          </div>
        </section>
      </section>
    );
  }
}

const {
  showModal,
} = uiActions;

const {
  fetchOffers,
} = offersActions;

const {
  getCurrentUser,
  getOffers,
}  = authSelectors;

const mapStateToProps = state => ({
  user: getCurrentUser(state),
  offers: getOffers(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    showModal,
    fetchOffers,
  }, dispatch),
});

export default translate('translations')(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pricing));
