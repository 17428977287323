import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {translate} from "react-i18next";
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {bindActionCreators, compose} from "redux";
import {actions as uiActions, ModalTypes, selectors as uiSelectors} from 'shared/redux/ui';
import i18next from "i18next";

const styles = theme => ({
  card: {
    backgroundColor: theme.palette.lightGray,
    paddingTop: 16,
    borderRadius: 16,
    marginTop: 60,
    marginBottom: 60,
    marginLeft: 12,
    marginRight: 12,
    typography: 'BrandonText',
    boxShadow: '0px 7px 16px 3px rgb(0 0 0 / 13%)',
    fontSize: 14,
    color: '#333333',
    display: 'flex',
    width: 'auto',
    maxWidth: 270,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginTop: 20,
      marginBottom: 20
    }
  },
  button: {
    textTransform: 'none',
    backgroundColor: '#2eb575 !important',
    color: 'white',
    padding: '7px 32px',
    borderRadius: 7
  },
  price: {
    fontSize: '40px !important',
    color: '#008aae',
    fontWeight: 700,
    marginRight: 8
  },
  hr: {
    height: 2,
    backgroundColor: '#008aae',
    border: 'none',
    margin: 0
  },
  benefits: {
    backgroundColor: 'white',
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    padding: '16px 16px 27px 16px',
    textAlign: 'left',
    flex: '2 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  accent: {
    fontWeight: 600
  },
  accentBrand: {
    color: '#008aae',
    fontWeight: 600
  },
  row: {
    marginBottom: 16
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: 25
  },
  benefitsTop: {
    backgroundColor: theme.palette.lightGray,
    fontSize: 18,
    paddingTop: 16
  }
});

const formatPrice = v => v / 100;
const formatSizes = v => v / 1024;

class OfferCard extends Component {
  constructor(props) {
    super(props);
    this.openRegistrationModal = this.openRegistrationModal.bind(this);
    this.getOfferName = this.getOfferName.bind(this);
  }
  openRegistrationModal() {
    this.props.showModal({
      modalType: ModalTypes.Registration,
    });
  }
  getOfferName(code) {
    if (code.includes('MINI')) {
      return 'MINI';
    }
    if (code.includes('BASIC')) {
      return 'BASIC';
    }
    if (code.includes('BUSINESS')) {
      return 'BUSINESS';
    }
    if (code.includes('ADVANCED')) {
      return 'ADVANCED';
    }
  }
  render() {
    const {classes, offer, type, t} = this.props;
    const maxFileSizeInKiB = parseInt(Math.floor(Math.log(offer.offerSettings.maxFileSizeInKiB) / Math.log(1024)));
    const maxFileSizeMb = Math.round(offer.offerSettings.maxFileSizeInKiB / Math.pow(1024, maxFileSizeInKiB), 2) + ' MB';
    let quality = '4K';
    if (offer && offer.offerSettings && offer.offerSettings.qualityGrade && offer.offerSettings.qualityGrade === 'HIGH') {
      quality = '6K';
    } else if (offer && offer.offerSettings && offer.offerSettings.qualityGrade && offer.offerSettings.qualityGrade === 'HIGHER') {
      quality = '8K';
    }
    const maxFileSize = Math.round(offer.offerSettings.maxFileSizeInKiB/1024);
    const langPl = i18next.language.includes('pl');
    return (
      <Grid item direction="column" className={classes.card}>
        <Typography variant="h6">{this.getOfferName(offer.code)}</Typography>
        <Grid container alignItems="center" justify="center">
          <Typography className={classes.price}>{formatPrice(offer.nominalNetPrice)}</Typography>
          <span style={{marginRight: 5}}>{offer.currency}</span>
          <Typography>
            {type === 'MONTHLY' ? (
              <span>{t("pricing.offers.currency")}</span>
            ) : (
              <span>{t("pricing.offers.currency_2")}</span>
            )}</Typography>
        </Grid>
        <hr className={classes.hr}/>
        <Grid className={classes.benefitsTop}>
          <Grid direction="row" className={classes.row}>
            <span className={classes.accentBrand}>
              {offer.offerSettings.limitOfPublishedPresentations
                ? `${offer.offerSettings.limitOfPublishedPresentations} `
                : t("pricing.offers.noLimit")
              }
            </span>
            {t("pricing.offers.publishedPresentation")}
          </Grid>
          <Grid direction="row" className={classes.row}>
            <span className={classes.accentBrand}>
              {offer.offerSettings.limitOfUsers}
            </span>
            {offer.offerSettings.users}
            {offer.offerSettings.limitOfUsers === 1 ? (
              <span>{t("pricing.offers.user")}</span>) : (
              <span>{t("pricing.offers.users")}</span>
            )}
          </Grid>
        </Grid>
        <Grid className={classes.benefits}>
          <Grid>
            <Grid direction="row" className={classes.row}>
              <span className={classes.accent}>
                {formatSizes(offer.offerSettings.storageSize)}
                {' GB '}
              </span>
              {t("pricing.offers.virtualStorage")}
            </Grid>
            <Grid direction="row" className={classes.row}>
              <span className={classes.accent}>
                {quality}
              </span>
              {t("pricing.offers.maxDimension")}
            </Grid>
            <Grid direction="row" className={classes.row}>
              <span className={classes.accent}>
                {maxFileSizeMb}
              </span>
              {t("pricing.offers.maxFileSize")}
            </Grid>
            <Grid direction="row" className={classes.row}>
              <span className={classes.accent}>
                {t("pricing.offers.vrPresentation")}
              </span>
              {t("pricing.offers.vrPresentation2")}
            </Grid>
            {offer.offerSettings.rectifyThetaPhotospheres && (
              <Grid direction="row" className={classes.row}>
                <span className={classes.accent}>
                  {t("pricing.offers.autoResize")}
                </span>
                {t("pricing.offers.autoResize2")}
              </Grid>
            )}
            {offer.offerSettings.viewingAllowed && (
              <Grid direction="row" className={classes.row}>
                <span className={classes.accent}>
                  {t("pricing.offers.coviewing")}
                </span>
                {t("pricing.offers.coviewing2")}
              </Grid>
            )}
            {offer.offerSettings.imageCorrectionEnabled && (
            <Grid direction="row" className={classes.row}>
              <span className={classes.accent}>
                {t("pricing.offers.imageCorrector")}
              </span>
            </Grid>
            )}
            {offer.offerSettings.protectedPresentationAllowed && (
              <Grid direction="row" className={classes.row}>
                {langPl && (<span>{t("pricing.offers.protectedPresentation1")}</span>)}
                <span className={classes.accent}>
                  {t("pricing.offers.protectedPresentation2")}
                </span>
                {!langPl && (<span>{t("pricing.offers.protectedPresentation3")}</span>)}
              </Grid>
            )}
            {offer.offerSettings.allowVideoUpload && (
              <React.Fragment>
                <Grid direction="row" className={classes.row}>
                  <span className={classes.accent}>
                    {t("pricing.offers.allowVideoUpload")}
                  </span>
                </Grid>
                <Grid direction="row" className={classes.row}>
                  <span className={classes.accent}>
                    {maxFileSize} MB
                  </span>
                  {t("pricing.offers.videoSize2")}
                </Grid>
              </React.Fragment>
            )}
            {offer.offerSettings.advancedWidgetsEnabled && (
              <Grid direction="row" className={classes.row}>
                <span className={classes.accent}>
                  {t("pricing.offers.advancedWidgetsEnabled")}
                </span>
                {t("pricing.offers.advancedWidgetsEnabled2")}
              </Grid>
            )}
            {offer.offerSettings.debrandingEnabled && (
              <Grid direction="row" className={classes.row}>
                <span className={classes.accent}>
                  {t("pricing.offers.noEvryLogo")}
                </span>
              </Grid>
            )}
          </Grid>
          <Grid direction="row" className={classes.buttonContainer}>
            <Button className={classes.button} onClick={this.openRegistrationModal}>
              {t("pricing.offers.tryForFree")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}


OfferCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  offer: PropTypes.shape({
    offerSettings: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const {
  getModal,
} = uiSelectors;

const {
  showModal,
  hideModal,
} = uiActions;

const mapStateToProps = state => ({
  modal: getModal(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    showModal,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translations')(withStyles(styles)(OfferCard)));
